import hat1 from "./hat-1.jpg"
import hat2 from "./hat-2.jpg"
import hat3 from "./hat-3.jpg"
import hat4 from "./hat-4.jpg"
import hat5 from "./hat-5.jpg"
import hat6 from "./hat-6.jpg"
import hat7 from "./hat-7.jpg"


export default [
    {
        id: "1234",
        imgSrc: hat1,
        title: "Czapka z daszkiem",
        price: 80,
        currency: "PLN",
        description: "szara czapka typu baseball hat",
    },
    {
        id: "1235",
        imgSrc: hat2,
        title: "Brązowy kapelusz",
        price: 140,
        currency: "PLN",
        description: "brązowy kapelusz z czarną tasiemką ",
    },
    {
        id: "1236",
        imgSrc: hat3,
        title: "Czapka beanie",
        price: 75,
        currency: "PLN",
        description: "dzianinowa czapka typu beanie",
    },
    {
        id: "1237",
        imgSrc: hat4,
        title: "Psia czapka",
        price: 40,
        currency: "PLN",
        description: "zółta czapka dla pupila",
    },
    {
        id: "1238",
        imgSrc: hat5,
        title: "Czapka z aplikacją",
        price: 90,
        currency: "PLN",
        description: "czerwona czapka z daszkiem z białym haftem",
    },
    {
        id: "1239",
        imgSrc: hat6,
        title: "Męska czapka baseball hat",
        price: 100,
        currency: "PLN",
        description: "męska czarna czapka z daszkiem",
    },
    {
        id: "1240",
        imgSrc: hat7,
        title: "Damska czapka z aplikacją",
        price: 200,
        currency: "PLN",
        description: "damska czapka typu baseball hat z haftem",
    },
    {
        id: "1241",
        imgSrc: hat1,
        title: "Czapka z daszkiem",
        price: 80,
        currency: "PLN",
        description: "szara czapka typu baseball hat",
    },
    {
        id: "1242",
        imgSrc: hat2,
        title: "Brązowy kapelusz",
        price: 140,
        currency: "PLN",
        description: "brązowy kapelusz z czarną tasiemką ",
    },
    {
        id: "1243",
        imgSrc: hat3,
        title: "Czapka beanie",
        price: 75,
        currency: "PLN",
        description: "dzianinowa czapka typu beanie",
    },
    {
        id: "1244",
        imgSrc: hat4,
        title: "Psia czapka",
        price: 40,
        currency: "PLN",
        description: "zółta czapka dla pupila",
    },
    {
        id: "1245",
        imgSrc: hat5,
        title: "Czapka z aplikacją",
        price: 90,
        currency: "PLN",
        description: "czerwona czapka z daszkiem z białym haftem",
    },
    {
        id: "1246",
        imgSrc: hat6,
        title: "Męska czapka baseball hat",
        price: 100,
        currency: "PLN",
        description: "męska czarna czapka z daszkiem",
    },
    {
        id: "1247",
        imgSrc: hat7,
        title: "Damska czapka z aplikacją",
        price: 200,
        currency: "PLN",
        description: "damska czapka typu baseball hat z haftem",
    }
]